import React from 'react'
import { graphql } from 'gatsby'
import { News as NewsPageComponent } from '../components/pages'

const News: React.FC<Props> = ({ data, location }) => {
  const newsNode = data.news.nodes[0]

  return (
    <NewsPageComponent
      title={newsNode.title}
      gatsbyImageData={newsNode.image.gatsbyImageData}
      content={newsNode.content.content}
      location={location}
    />
  )
}

export default News

export const query = graphql`
  query NewsQuery {
    news: allContentfulNews(sort: { fields: createdAt, order: DESC }, limit: 1) {
      nodes {
        title
        content {
          content
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, height: 1440)
        }
      }
    }
  }
`

interface Props {
  data: {
    news: {
      nodes: NewsNode[]
    }
  }
  location: any
}

interface NewsNode {
  title: string
  content: {
    content: string
  }
  image: {
    gatsbyImageData: any
  }
}
